@use "sass:math";

/*
* BASE DEPENDENCIES
*/

@import "abstracts/mixins", "abstracts/functions", "abstracts/vars",
	"abstracts/animations";

//@import "components/blender";

.homepage {
	padding-top: 75px;
	padding-bottom: 75px;

	&__intro {
		display: grid;
		grid-gap: 50px;
		gap: 50px;
		margin-bottom: rem(-100px);
		position: relative;
		z-index: 2;

		@include min(800px) {
			grid-template-columns: 1fr 1fr;
		}
	}

	&__booking {
		padding-top: 1em;

		.booking {
			margin-right: auto;

			@include min(800px) {
				margin-right: 0;
			}
		}
	}
}

.header__info {
	background-color: rgba(white, 0.75);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	mask: url(/assets/images/info-mask.svg);
	mask-size: 100% 100%;
	mask-position: 0 0;
	mask-repeat: no-repeat;
	margin: 27vw -6px rem(60px);
	padding: rem(20px) rem(16px);
	display: flex;
	align-items: center;
	justify-content: center;

	@include min(500px) {
		margin-left: auto;
		margin-right: auto;
		padding: rem(30px);
	}

	@include minmax(1px, 749px) {
		.main-nav-mobile & {
			margin-top: calc(27vw + 65px);
		}
	}

	@include min(1024px) {
		min-height: rem(165px);
		padding: rem(20px) rem(62px);
		margin-top: auto;
		margin-bottom: rem(160px);
	}
}

.info {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: space-between;
	gap: 20px;

	@include min(500px) {
		width: 100%;
	}

	@include min(600px) {
		gap: 40px;
	}

	@include min(760px) {
		gap: 100px;
	}

	&__dt {
		font-size: rem(13px);
		font-weight: 300;
		color: $color-brand;

		@include min(600px) {
			font-size: rem(14px);
		}
	}

	&__dd {
		display: block;
		font-size: 1rem;
		font-weight: bold;
		color: $color-secondary;

		@include min(600px) {
			font-size: rem(22px);
		}
	}
}

.intro {
	@include min(800px) {
		padding-bottom: rem(150px);
	}

	.text-module {
		font-size: rem(18px);
		font-weight: 300;
		line-height: math.div(28, 18);
		margin: 0 0 50px;

		p {
			font-size: rem(18px);
			font-weight: 300;
			line-height: math.div(28, 18);
		}
	}

	&__subheading {
		color: $color-secondary;
		font-weight: bold;
		font-size: rem(24px);
		margin: 0 0 rem(40px);
		padding-bottom: rem(36px);
		position: relative;

		&::after {
			content: "";
			background-color: $color-secondary;
			position: absolute;
			width: 150px;
			height: 4px;
			bottom: 0;
			left: 0;
		}
	}
}

.about {
	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	background: {
		color: #faefe5;
		image: url(/assets/images/waves-top.svg), url(/assets/images/waves.svg);
		repeat: no-repeat;
		position: 50% 0, 50% 100%;
		size: auto, auto;
	}
	padding: rem(140px) 0;
	position: relative;

	@include min(720px) {
		background: {
			image: url(/assets/images/waves-top.svg), url(/assets/images/waves.svg),
				linear-gradient(
					to right,
					rgba(#faefe5, 0) 33%,
					#faefe5 50%,
					#faefe5 100%
				),
				url(/assets/images/map.svg);
			repeat: no-repeat;
			position: 50% 0, 50% 100%, 0 0, 18% 0;
			size: auto, auto, auto, auto 100%;
		}
	}

	@include min($max-width) {
		background-position: 50% 0, 50% 100%, 0 0, -200px 0;
	}

	@include min(1600px) {
		background-position: 50% 0, 50% 100%, 0 0, 35% 0;
	}

	@include min(1720px) {
		&::after {
			content: "";
			position: absolute;
			bottom: -50px;
			right: -60px;
			width: 397px;
			height: 423px;
			background: transparent url(/assets/images/eagle.svg) no-repeat 0 0 /
				contain;
		}
	}

	&__content {
		display: grid;

		@include min(720px) {
			grid-template-columns: 40% minmax(auto, 650px);

			&::before {
				content: "";
			}
		}
	}

	&__heading {
		color: $color-secondary;
		margin-bottom: rem(15px);
	}
	&__subheading {
		color: #574635;
		font-size: rem(34px);
		font-weight: bold;
		margin: 0 0 rem(36px);
	}

	.text-module {
		font-size: rem(18px);
		font-weight: 300;
		line-height: math.div(28, 18);
		margin-bottom: 3rem;

		p {
			font-size: rem(18px);
			font-weight: 300;
			line-height: math.div(28, 18);
		}
	}
}

.trips {
	padding: rem(115px) 0 rem(100px);

	&__heading {
		text-align: center;
	}

	&__list {
		display: grid;
		grid-gap: 50px;
		gap: 50px;
		margin: rem(50px) 0 0;

		@include min(700px) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include min(1200px) {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	&__image-wrapper {
		background: transparent url(/assets/images/bg-image-wrapper.svg) no-repeat
			999rem 0 / 100% 100%;
		display: block;
		line-height: 0;
	}

	&__image {
		mask: url(/assets/images/trip-mask.svg);
		mask-size: 100% 100%;
		mask-position: center;
		mask-repeat: no-repeat;
		background-color: $color-secondary;
		aspect-ratio: 594 / 609;
		object-fit: cover;
		object-position: center;
		transition: mask-size 0.1s ease-out;
	}

	&__text {
		padding-left: rem(26px);
		padding-right: rem(26px);
		margin-left: 36px;
		margin-right: 36px;
		border-radius: 28px;
		background-color: rgba(white, 0.9);
		-webkit-backdrop-filter: blur(0);
		backdrop-filter: blur(0);
		transition: backdrop-filter 0.1s ease-out, background-color 0.1s ease-out;
		position: relative;
	}

	&__item {
		display: flex;
		flex-direction: column;

		@include min(700px) {
		}

		@include minmax(1px, 1199px) {
			.trips__text {
				margin-top: rem(-40px);
				padding-top: rem(64px);
			}
		}

		@include min(1200px) {
			&:nth-child(odd) {
				flex-direction: column-reverse;

				.trips__text {
					margin-bottom: rem(-55px);
					padding-bottom: rem(64px);
				}
			}
			&:nth-child(even) {
				.trips__text {
					margin-top: rem(-40px);
					padding-top: rem(64px);
				}
			}
		}

		&:hover,
		&:focus-within {
			.trips__image-wrapper {
				background-position: 0 0;
			}
			.trips__image {
				mask-size: 94% 94%;
			}

			.trips__text {
				background-color: rgba(white, 0.5);
				-webkit-backdrop-filter: blur(15px);
				backdrop-filter: blur(15px);
			}
		}
	}

	&__admin-link {
		position: relative;
		z-index: 10;
	}

	&__link {
		&::before {
			content: "";
			@include fill;
		}
		@include link(&) {
			font-family: $font-headings;
			font-size: rem(34px);
			line-height: 1.2;
			color: $color-brand;
			text-decoration: none;
			font-weight: bold;
		}

		@include link-over(&) {
			color: $color-secondary;
			text-decoration: underline;
			text-decoration-thickness: 2px;
		}
	}

	&__distance {
		margin: rem(8px) 0 0 0;
		font-size: rem(18px);
	}
}
