/*
* BASE DEPENDENCIES
*/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*
@keyframes fade-into-foreground {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(1.2);
		opacity: 0;
	}
}

@keyframes fade-into-background {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(.75);
		opacity: 0;
	}
}

@keyframes pop-into-foreground {
	from {
		transform: scale(.5);
		opacity: 0;
	} to {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pop-into-foreground-with-bounce {
	0% {
		transform: scale(.5);
		opacity: 0;
	} 72% {
		transform: scale(1.07);
		opacity: .72;
	} 100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
	} 100% {
		box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0);
	}
}

@keyframes slide-from-top {
	from {
		transform: translateY(-100%);
	} to {
		transform: translateY(0);
	}
}
*/
.homepage {
  padding-top: 75px;
  padding-bottom: 75px;
}
.homepage__intro {
  display: grid;
  grid-gap: 50px;
  gap: 50px;
  margin-bottom: -6.25rem;
  position: relative;
  z-index: 2;
}
@media screen and (min-width: 800px) {
  .homepage__intro {
    grid-template-columns: 1fr 1fr;
  }
}
.homepage__booking {
  padding-top: 1em;
}
.homepage__booking .booking {
  margin-right: auto;
}
@media screen and (min-width: 800px) {
  .homepage__booking .booking {
    margin-right: 0;
  }
}

.header__info {
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  mask: url(/assets/images/info-mask.svg);
  mask-size: 100% 100%;
  mask-position: 0 0;
  mask-repeat: no-repeat;
  margin: 27vw -6px 3.75rem;
  padding: 1.25rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 500px) {
  .header__info {
    margin-left: auto;
    margin-right: auto;
    padding: 1.875rem;
  }
}
@media screen and (min-width: 0.0625rem) and (max-width: 46.8125rem) {
  .main-nav-mobile .header__info {
    margin-top: calc(27vw + 65px);
  }
}
@media screen and (min-width: 1024px) {
  .header__info {
    min-height: 10.3125rem;
    padding: 1.25rem 3.875rem;
    margin-top: auto;
    margin-bottom: 10rem;
  }
}

.info {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
@media screen and (min-width: 500px) {
  .info {
    width: 100%;
  }
}
@media screen and (min-width: 600px) {
  .info {
    gap: 40px;
  }
}
@media screen and (min-width: 760px) {
  .info {
    gap: 100px;
  }
}
.info__dt {
  font-size: 0.8125rem;
  font-weight: 300;
  color: #083735;
}
@media screen and (min-width: 600px) {
  .info__dt {
    font-size: 0.875rem;
  }
}
.info__dd {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  color: #b16300;
}
@media screen and (min-width: 600px) {
  .info__dd {
    font-size: 1.375rem;
  }
}

@media screen and (min-width: 800px) {
  .intro {
    padding-bottom: 9.375rem;
  }
}
.intro .text-module {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.5555555556;
  margin: 0 0 50px;
}
.intro .text-module p {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.5555555556;
}
.intro__subheading {
  color: #b16300;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 0 0 2.5rem;
  padding-bottom: 2.25rem;
  position: relative;
}
.intro__subheading::after {
  content: "";
  background-color: #b16300;
  position: absolute;
  width: 150px;
  height: 4px;
  bottom: 0;
  left: 0;
}

.about {
  width: 100%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  background-color: #faefe5;
  background-image: url(/assets/images/waves-top.svg), url(/assets/images/waves.svg);
  background-repeat: no-repeat;
  background-position: 50% 0, 50% 100%;
  background-size: auto, auto;
  padding: 8.75rem 0;
  position: relative;
}
@media screen and (min-width: 720px) {
  .about {
    background-image: url(/assets/images/waves-top.svg), url(/assets/images/waves.svg), linear-gradient(to right, rgba(250, 239, 229, 0) 33%, #faefe5 50%, #faefe5 100%), url(/assets/images/map.svg);
    background-repeat: no-repeat;
    background-position: 50% 0, 50% 100%, 0 0, 18% 0;
    background-size: auto, auto, auto, auto 100%;
  }
}
@media screen and (min-width: 1422px) {
  .about {
    background-position: 50% 0, 50% 100%, 0 0, -200px 0;
  }
}
@media screen and (min-width: 1600px) {
  .about {
    background-position: 50% 0, 50% 100%, 0 0, 35% 0;
  }
}
@media screen and (min-width: 1720px) {
  .about::after {
    content: "";
    position: absolute;
    bottom: -50px;
    right: -60px;
    width: 397px;
    height: 423px;
    background: transparent url(/assets/images/eagle.svg) no-repeat 0 0/contain;
  }
}
.about__content {
  display: grid;
}
@media screen and (min-width: 720px) {
  .about__content {
    grid-template-columns: 40% minmax(auto, 650px);
  }
  .about__content::before {
    content: "";
  }
}
.about__heading {
  color: #b16300;
  margin-bottom: 0.9375rem;
}
.about__subheading {
  color: #574635;
  font-size: 2.125rem;
  font-weight: bold;
  margin: 0 0 2.25rem;
}
.about .text-module {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.5555555556;
  margin-bottom: 3rem;
}
.about .text-module p {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.5555555556;
}

.trips {
  padding: 7.1875rem 0 6.25rem;
}
.trips__heading {
  text-align: center;
}
.trips__list {
  display: grid;
  grid-gap: 50px;
  gap: 50px;
  margin: 3.125rem 0 0;
}
@media screen and (min-width: 700px) {
  .trips__list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1200px) {
  .trips__list {
    grid-template-columns: repeat(4, 1fr);
  }
}
.trips__image-wrapper {
  background: transparent url(/assets/images/bg-image-wrapper.svg) no-repeat 999rem 0/100% 100%;
  display: block;
  line-height: 0;
}
.trips__image {
  mask: url(/assets/images/trip-mask.svg);
  mask-size: 100% 100%;
  mask-position: center;
  mask-repeat: no-repeat;
  background-color: #b16300;
  aspect-ratio: 594/609;
  object-fit: cover;
  object-position: center;
  transition: mask-size 0.1s ease-out;
}
.trips__text {
  padding-left: 1.625rem;
  padding-right: 1.625rem;
  margin-left: 36px;
  margin-right: 36px;
  border-radius: 28px;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-backdrop-filter: blur(0);
  backdrop-filter: blur(0);
  transition: backdrop-filter 0.1s ease-out, background-color 0.1s ease-out;
  position: relative;
}
.trips__item {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 0.0625rem) and (max-width: 74.9375rem) {
  .trips__item .trips__text {
    margin-top: -2.5rem;
    padding-top: 4rem;
  }
}
@media screen and (min-width: 1200px) {
  .trips__item:nth-child(odd) {
    flex-direction: column-reverse;
  }
  .trips__item:nth-child(odd) .trips__text {
    margin-bottom: -3.4375rem;
    padding-bottom: 4rem;
  }
  .trips__item:nth-child(even) .trips__text {
    margin-top: -2.5rem;
    padding-top: 4rem;
  }
}
.trips__item:hover .trips__image-wrapper, .trips__item:focus-within .trips__image-wrapper {
  background-position: 0 0;
}
.trips__item:hover .trips__image, .trips__item:focus-within .trips__image {
  mask-size: 94% 94%;
}
.trips__item:hover .trips__text, .trips__item:focus-within .trips__text {
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}
.trips__admin-link {
  position: relative;
  z-index: 10;
}
.trips__link::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.trips__link, .trips__link:link, .trips__link:visited {
  font-family: "TiltWarp", Poppins, Montserrat, sans-serif;
  font-size: 2.125rem;
  line-height: 1.2;
  color: #083735;
  text-decoration: none;
  font-weight: bold;
}
.trips__link:hover, .trips__link:focus, .trips__link:active {
  color: #b16300;
  text-decoration: underline;
  text-decoration-thickness: 2px;
}
.trips__distance {
  margin: 0.5rem 0 0 0;
  font-size: 1.125rem;
}